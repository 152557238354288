// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import { Paginated, SortingOption } from "@/interfaces/paginated";
// @ts-ignore
import i18n from "@/plugins/i18n";

export function preparedColumns(){
    return [
        {
            text: i18n.t('models.table.id'),
            align: "center",
            sortable: false,
            filterable: false,
            value: "id",
        },
        {
            text: i18n.t('models.table.name'),
            align: "center",
            sortable: false,
            filterable: false,
            value: "name",
        },
        {
            text: i18n.t('models.table.archived'),
            align: "center",
            sortable: false,
            filterable: false,
            value: "active",
        }
    ];
};

export function preparedData(){
    return {
        paginated: { page: 1, limit: 25 } as Paginated,
        filters: {},
        options: getDefaultSortingOption("id"),
        table: {
            headers: [],
            items: [],
        }
    }
};